import { useEffect, useRef, useMemo, useState, useLayoutEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux'
import { v4 as uuid } from 'uuid'

import { StoreType, RouterStateType, IRouterItemHistory } from 'redux/type'
import { IUseRouterHistories } from './type.d'
import { LAYOUT_NAME } from 'layouts/type.d'
import { defaultRouteItem } from 'router'
import { RouterItemType } from 'router/type.d'
import { USER_ROLES, UserAzureType } from 'services/user/type.d'
// import { USER_ROLES } from 'services/user/type.d'

export const usePrevious: any = (state: any): any => {
  const ref: any = useRef()

  useEffect((): void => {
    ref.current = state
  }, [state])

  return ref.current
}

export const useCheckIsLogedIn: any = (user: any): any => {
  return useMemo((): boolean => {
    // return user && !!user.signInUserSession?.idToken?.jwtToken
    return user
  }, [user])
}

const paramsToObject: any = (entries: any): object => {
  const result: any = {}
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value
  }
  return result
}
export const useQuery: any = (state: any): void => {
  const { search } = useLocation()

  const query: any = useMemo((): any => {
    return new URLSearchParams(search)
  }, [search])

  return paramsToObject(query.entries())
}

interface IPagingTextParams {
  actualPageSize: number
  pageSize: number
  currentPage: number
  totalPages: number
  totalRows: number
}
export const usePagingText: any = ({
  actualPageSize,
  pageSize,
  currentPage,
  totalPages,
  totalRows
}: IPagingTextParams): any => {
  const firstRecordNo: number = (currentPage - 1) * pageSize + 1
  const lastRecordNo: number = firstRecordNo + actualPageSize - 1

  return {
    firstRecordNo,
    lastRecordNo,
    pageSize,
    actualPageSize,
    currentPage,
    totalPages,
    totalRows
  }
}

export interface IUseUserRole {
  role: USER_ROLES | string
  isMaker: boolean
  isChecker: boolean
  isSuperMaker: boolean
  isSuperChecker: boolean
  isViewer: boolean
  is: (roleEnum: USER_ROLES) => boolean
  isInsideGroup: (group: USER_ROLES[]) => boolean
}

export const useUserRole: any = (): IUseUserRole => {
  const user: UserAzureType = useSelector(
    (state: StoreType): UserAzureType => state.user.currentUser,
    shallowEqual
  )

  const role: string = useMemo((): string => {
    const key: string = 'profile'

    // if (!user?.attributes?.[key]) {
    if (!user?.[key]) {
      // if (!user?.email) {
      return ''
    }

    const obj: any = JSON.parse(user?.[key])
    // const obj: any = convertRoleFromEmail(user?.email)

    // return obj.role
    return obj.role
  }, [user])

  const is: any = (roleEnum: USER_ROLES): boolean => {
    return role === roleEnum
  }

  const isInsideGroup: any = (group: USER_ROLES[]): boolean => {
    for (const roleEnum of group) {
      if (roleEnum === role) {
        return true
      }
    }

    return false
  }

  return {
    role,
    is,
    isInsideGroup,
    isMaker: role === USER_ROLES.MAKER,
    isChecker: role === USER_ROLES.CHECKER,
    isSuperMaker: role === USER_ROLES.SUPER_MAKER,
    isSuperChecker: role === USER_ROLES.SUPER_CHECKER,
    isViewer: role === USER_ROLES.VIEWER
  }
}

export const useRouterHistories: any = (): IUseRouterHistories => {
  const history: any = useHistory()

  const { current, histories }: RouterStateType = useSelector(
    (state: StoreType): RouterStateType => state.router,
    shallowEqual
  )

  const defaultRouterItemHistory: IRouterItemHistory =
    useMemo((): IRouterItemHistory => {
      const defaultItem: RouterItemType = { ...defaultRouteItem }
      delete defaultItem.component

      return {
        ...defaultItem,
        location: {
          hash: '',
          key: uuid(),
          pathname: '/',
          search: ''
        }
      }
    }, [])

  const prevPage: IRouterItemHistory = useMemo((): IRouterItemHistory => {
    if (histories[histories.length - 2]) {
      return histories[histories.length - 2]
    }

    return defaultRouterItemHistory
  }, [histories, defaultRouterItemHistory])

  const getPathFromRouterItemHistory: any = (
    item: IRouterItemHistory
  ): string => {
    return `${item.location.pathname}${item.location.search}${item.location.hash}`
  }

  const prevPagePath: string = useMemo((): string => {
    return getPathFromRouterItemHistory(prevPage)
  }, [prevPage])

  const lastPageIsNotInsideAdmin: boolean = useMemo((): boolean => {
    return prevPage.layout !== LAYOUT_NAME.ADMIN
  }, [prevPage])

  const goToAdminPreferPrevPage: any = (): void => {
    const path: string = lastPageIsNotInsideAdmin ? '/' : prevPagePath
    history.push(path)
  }

  const findClosestPrevPageInAdmin: any = (): IRouterItemHistory => {
    let item: IRouterItemHistory | null = null

    if (histories.length < 2) {
      return defaultRouterItemHistory
    }

    for (let i: number = histories.length - 2; i > 0; i--) {
      const currentItem: IRouterItemHistory = histories[i]

      if (currentItem.layout === LAYOUT_NAME.ADMIN) {
        item = currentItem
        break
      }
    }

    return item || defaultRouterItemHistory
  }

  const goBackOrGoDashboard: any = (): void => {
    const closestHistory: IRouterItemHistory = findClosestPrevPageInAdmin()
    const closestUrl: string = getPathFromRouterItemHistory(closestHistory)
    history.push(closestUrl)
  }

  return {
    current,
    histories,
    prevPage,
    prevPagePath,
    lastPageIsNotInsideAdmin,
    goToAdminPreferPrevPage,
    goBackOrGoDashboard
  }
}

export const useWindowSize: any = (): any => {
  const [size, setSize] = useState<any>([0, 0])
  useLayoutEffect((): any => {
    const updateSize: any = (): any => {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return (): any => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}
