export const formartPhoneNoWithCountryCodeStr: any = (
  phoneNo: string
): string => {
  const block4: string = phoneNo?.substring(
    phoneNo?.length - 4,
    phoneNo?.length
  )
  const block3: string = phoneNo?.substring(
    phoneNo?.length - 7,
    phoneNo?.length - 4
  )
  const block2: string = phoneNo?.substring(
    phoneNo?.length - 9,
    phoneNo?.length - 7
  )
  const block1: string = phoneNo?.substring(0, phoneNo?.length - 9)

  return `${block1} ${block2} ${block3} ${block4}`.trim().replace(/\s\s+/g, ' ')
}

export const formartPhoneNoWithoutCountryCodeStr: any = (
  phoneNo: string
): string => {
  if (phoneNo?.substring(0, 3) === '+84') {
    const block3: string = phoneNo?.substring(
      phoneNo?.length - 4,
      phoneNo?.length
    )
    const block2: string = phoneNo?.substring(
      phoneNo?.length - 7,
      phoneNo?.length - 4
    )
    const block1: string = phoneNo?.substring(
      phoneNo?.length - 9,
      phoneNo?.length - 7
    )
    const block0: string = phoneNo?.substring(
      phoneNo?.length - 11,
      phoneNo?.length - 9
    )

    return `${block0} ${block1} ${block2} ${block3} `
      .trim()
      .replace(/\s\s+/g, ' ')
  } else {
    const block3: string = phoneNo?.substring(
      phoneNo?.length - 4,
      phoneNo?.length
    )
    const block2: string = phoneNo?.substring(
      phoneNo?.length - 7,
      phoneNo?.length - 4
    )
    const block1: string = phoneNo?.substring(
      phoneNo?.length - 9,
      phoneNo?.length - 7
    )
    const block0: string = phoneNo?.substring(
      phoneNo?.length - 12,
      phoneNo?.length - 9
    )

    return `${block0} ${block1} ${block2} ${block3} `
      .trim()
      .replace(/\s\s+/g, ' ')
  }
}

export const formartNationalIdWithoutCountryCodeStr: any = (
  nationalId: string
): string => {
  return nationalId?.substring(4, nationalId?.length)?.trim()
}

export const formatPhoneSpokeAccount: any = (phoneNo: string = ''): string => {
  const block1: string = phoneNo.substring(0, 3)
  const block2: string = phoneNo.substring(3, 6)
  const block3: string = phoneNo.substring(6)

  return `${block1} ${block2} ${block3}`.trim().replace(/\s\s+/g, ' ')
}

export const compactFileUrl: any = (
  url: string,
  length: number = 32
): string => {
  if (typeof url !== 'string') {
    return ''
  }

  const minLength: number = 10

  if (url.length <= length || url.length <= minLength) {
    return url
  }

  if (length < minLength) {
    return `...${url.slice(-(length - 3))}`
  }

  const first: string = url.slice(0, 10)
  const last: string = url.slice(-(length - 13))

  return `${first}...${last}`
}

export const capitalizeFirstLetter: any = (string: string = ''): string => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export const toCamelCase: any = (str: string = ''): string => {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m: any, chr: any): string =>
      chr.toUpperCase()
    )
}

export const formatAddress: any = (string: string = ''): string => {
  const array: string[] = string.split('_')
  for (let i: number = 0; i < array.length; i++) {
    array[i] =
      array[i].charAt(0).toUpperCase() + array[i].slice(1).toLowerCase()
  }
  const strFormat: string = array.join(' ')
  return strFormat
}

export const formatNumberWithComma: any = (number: number): string => {
  const options: {
    minimumFractionDigits: number
    maximumFractionDigits: number
  } = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }
  return Number(number).toLocaleString('en', options)
}

export const checkFormatEmailComponent: any = (email: string): any => {
  const format: any = /^[a-zA-Z0-9._-]+@(?!-)[a-zA-Z0-9.-]+(?:\.[a-zA-Z0-9]+)$/

  const checkNewValueIsValid: boolean = Boolean(format.test(email))

  return checkNewValueIsValid
}

export const formartRequestIdCoporatePricingManagement: any = (
  requestId: string
): string => {
  const id: string = requestId?.substring(
    requestId?.length - 10,
    requestId?.length
  )

  return id.trim().replace(/\s\s+/g, ' ')
}

export const getTokenIDJWT: any = (str: string): any => {
  const splitFirstTime: string | undefined = str
    .split('#')
    .find((item: string): any => {
      return item.includes('id_token') || ''
    })

  const splitSecondTime: string | undefined = splitFirstTime
    ?.split('&')
    .find((item: string): any => {
      return item.includes('id_token') || ''
    })

  const splitThirdTime: string | undefined = splitSecondTime
    ?.split('=')
    .find((item: string): any => {
      return !item.includes('id_token') || ''
    })

  return splitThirdTime
}

export const getPathFromAzure: any = (str: string): any => {
  const splitFirstTime: string | undefined = str
    .split('#')
    .find((item: string): any => {
      return item.includes('state') || ''
    })

  const splitSecondTime: string | undefined = splitFirstTime
    ?.split('&')
    .find((item: string): any => {
      return item.includes('state') || ''
    })

  const splitThirdTime: string | undefined = splitSecondTime
    ?.split('=')
    .find((item: string): any => {
      return !item.includes('state') || ''
    })

  return splitThirdTime
}
