import { FC, useEffect, useCallback, useRef } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { History } from 'history'
import moment, { Moment } from 'moment'

import IdleTimer, { IdleTimerAPI } from 'react-idle-timer'

import AuthService from 'services/user/auth.service'
import { BroadcastListener } from './BroadcastListener'
import { useCheckIsLogedIn } from 'hooks'
import { StoreType } from 'redux/type.d'

// import { UserType } from 'services/user/type.d'

import { IProps } from './type'
import {
  getLastTimeHasActionOnBrowser,
  setLastTimeHasActionOnBrowser
} from './helper'
import { UserAzureType } from 'services/user/type.d'

export const BackgroundTasks: FC<IProps> = (): JSX.Element => {
  const user: UserAzureType = useSelector(
    (state: StoreType): UserAzureType => state.user.currentUser,
    shallowEqual
  )
  const isCustomerSupport: boolean | null = Boolean(
    sessionStorage.getItem('isCustomerSupport')
  )

  const timeoutConfig: number = isCustomerSupport ? 1800000 : 600000
  const userStr: string | null = localStorage.getItem('user')
  const idleTimer: any = useRef<IdleTimerAPI | null>(null)
  const history: History = useHistory()
  const isLogedIn: boolean = useCheckIsLogedIn(user)

  if (!user) {
    history.push('/login')
  }

  const handleOnIdle: any = useCallback((): void => {
    if (userStr) {
      AuthService.logoutAzure()
    } else {
      AuthService.logout()
      history.push('/login')
    }
    // eslint-disable-next-line
  }, [])

  const handleOnAction: any = (): void => {
    setLastTimeHasActionOnBrowser()
  }

  const logoutOnFirstTimeMountIfExpired: any = useCallback((): void => {
    const lastTimeHasAction: Moment | null = getLastTimeHasActionOnBrowser()

    if (!lastTimeHasAction) {
      return
    }

    const expiredTime: Moment = lastTimeHasAction
      .clone()
      .add(timeoutConfig, 'milliseconds')
    const current: Moment = moment()

    const isExpired: boolean = current.isAfter(expiredTime)
    if (isExpired) {
      handleOnIdle()
    }
  }, [handleOnIdle, timeoutConfig])

  useEffect((): void => {
    if (!isLogedIn) {
      history.push('/login')
    }
  }, [isLogedIn, history])

  useEffect((): void => {
    logoutOnFirstTimeMountIfExpired()
  }, [logoutOnFirstTimeMountIfExpired])

  return (
    <>
      <BroadcastListener />

      <IdleTimer
        ref={idleTimer}
        crossTab={{
          emitOnAllTabs: true
        }}
        timeout={timeoutConfig}
        onIdle={handleOnIdle}
        onAction={handleOnAction}
        debounce={250}
      />
    </>
  )
}
