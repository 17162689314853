import { FC, useState, useMemo } from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import { IProps } from './type'
import { NavBar } from './NavBar'
import { Menu } from './Menu'
import { Content } from './Content'
import { useCheckIsLogedIn } from 'hooks'

// import { UserType } from 'services/user/type.d'
import { UserAzureType } from 'services/user/type.d'
import { StoreType } from 'redux/type.d'

import './style.scss'

export const GradientAdminLayout: FC<IProps> = ({
  children
}: IProps): JSX.Element => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  // const user: UserType = useSelector(
  //   (state: StoreType): UserType => state.user.currentUser,
  const user: UserAzureType = useSelector(
    (state: StoreType): UserAzureType => state.user.currentUser,
    shallowEqual
  )

  const isLogedIn: boolean = useCheckIsLogedIn(user)

  const layoutClass: string = useMemo((): string => {
    return isLogedIn ? 'gradient-layout -is-logedin' : 'gradient-layout'
  }, [isLogedIn])

  const menuClasses: string = useMemo((): string => {
    return `gradient-layout__body__left ${isCollapsed ? '-collapsed-menu' : ''}`
  }, [isCollapsed])

  const contentClasses: string = useMemo((): string => {
    return `gradient-layout__body__right ${
      isCollapsed ? '-collapsed-menu' : ''
    }`
  }, [isCollapsed])

  return (
    <>
      <div className={layoutClass}>
        <NavBar isCollapsed={isCollapsed} />

        <div className="gradient-layout__body">
          <div className={menuClasses}>
            <Menu isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
          </div>

          <div className={contentClasses}>
            <Content>{children}</Content>
          </div>
        </div>
      </div>
    </>
  )
}
