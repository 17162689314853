import { lazy } from 'react'
import {
  ADMIN_ACTIVE_KEY_MENU,
  RouterItemType,
  ROUTER_NAME_LIST
} from './type.d'
import { LAYOUT_NAME } from 'layouts/type.d'
import { USER_ROLES } from 'services/user/type.d'

export const defaultRouteItem: RouterItemType = {
  path: '/',
  exact: true,
  component: lazy((): Promise<any> => import('pages/Dashboard')),
  layout: LAYOUT_NAME.ADMIN,
  isPrivate: true,
  adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.DASHBOARD,
  name: ROUTER_NAME_LIST.DASHBOARD
}

export const routers: RouterItemType[] = [
  {
    path: '/',
    exact: true,
    component: lazy((): Promise<any> => import('pages/Dashboard')),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.DASHBOARD,
    name: ROUTER_NAME_LIST.DASHBOARD
  },
  {
    path: '/logout',
    exact: true,
    component: lazy((): Promise<any> => import('pages/Logout')),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.DASHBOARD,
    name: ROUTER_NAME_LIST.LOGOUT
  },
  {
    path: '/login',
    exact: false,
    component: lazy((): Promise<any> => import('pages/Login')),
    layout: LAYOUT_NAME.AUTHEN,
    isPrivate: false,
    logoConfig: {
      isDisable: true
    },
    name: ROUTER_NAME_LIST.LOGIN
  },
  {
    path: '/customers',
    exact: true,
    component: lazy((): Promise<any> => import('pages/Customers')),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.CUSTOMERS
  },
  {
    path: '/customers/spoke-account-detail/:id',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Customers/pages/SpokeDetailFrontPage')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.SPOKE_DETAIL_FRONT_PAGE
  },
  {
    path: '/customers/spoke-account-detail/:id/back-page',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Customers/pages/SpokeDetailBackPage')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.SPOKE_DETAIL_BACK_PAGE,
    accessWhiteList: [
      USER_ROLES.CHECKER,
      USER_ROLES.MAKER,
      USER_ROLES.SUPER_CHECKER,
      USER_ROLES.SUPER_MAKER
    ]
  },
  {
    path: '/customers/spoke-account-detail/phone/:phone/back-page',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Customers/pages/SpokeDetailBackPage')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.SPOKE_DETAIL_BACK_PAGE_PHONE,
    accessWhiteList: [
      USER_ROLES.CHECKER,
      USER_ROLES.MAKER,
      USER_ROLES.SUPER_CHECKER,
      USER_ROLES.SUPER_MAKER
    ]
  },
  {
    path: '/customers/spoke-account/:id/edit',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Customers/pages/EditSpokeAccount')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.SPOKE_EDIT_SPOKE,
    accessWhiteList: [USER_ROLES.MAKER]
  },
  {
    path: '/customers/spoke-account/:id/preview',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Customers/pages/EditSpokeAccount')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.SPOKE_PREVIEW_EXIST_REQUEST_EDIT_SPOKE,
    accessWhiteList: [
      USER_ROLES.CHECKER,
      USER_ROLES.MAKER,
      USER_ROLES.SUPER_CHECKER,
      USER_ROLES.SUPER_MAKER
    ]
  },
  {
    path: '/customers/spoke-management',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Customers/pages/SpokeManagement')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.SPOKE_REQUEST_EDIT_MANAGEMENT,
    accessWhiteList: [
      USER_ROLES.CHECKER,
      USER_ROLES.MAKER,
      USER_ROLES.SUPER_CHECKER,
      USER_ROLES.SUPER_MAKER
    ]
  },
  {
    path: '/customers/spoke-management/edit/:id',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Customers/pages/EditSpokeAccount')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.SPOKE_EDIT_EXIST_REQUEST_EDIT_SPOKE,
    accessWhiteList: [USER_ROLES.MAKER]
  },
  {
    path: '/customers/corporate-detail/:id/uploaded-employee-list',
    exact: true,
    component: lazy(
      (): Promise<any> =>
        import(
          'pages/Customers/pages/CorporateDetailFrontPage/tabs/HubAccounts/UploadEmployeeList/ViewAndMaintainUploadEmployeeList'
        )
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.CORPORATE_VIEW_AND_MAINTAIN_UPLOADED_EMPLOYEE_LIST,
    accessWhiteList: [
      USER_ROLES.CHECKER,
      USER_ROLES.MAKER,
      USER_ROLES.SUPER_CHECKER,
      USER_ROLES.SUPER_MAKER
    ]
  },
  {
    path: '/customers/hub-account/:id/edit',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Customers/pages/EditHubAccount')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.HUB_EDIT_HUB,
    accessWhiteList: [USER_ROLES.MAKER]
  },
  {
    path: '/customers/hub-account/phone/:phone/edit',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Customers/pages/EditHubAccount')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.HUB_EDIT_HUB,
    accessWhiteList: [USER_ROLES.MAKER]
  },
  {
    path: '/customers/hub-account/:id/preview',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Customers/pages/EditHubAccount')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.HUB_PREVIEW_EXIST_REQUEST_EDIT_HUB,
    accessWhiteList: [
      USER_ROLES.CHECKER,
      USER_ROLES.MAKER,
      USER_ROLES.SUPER_CHECKER,
      USER_ROLES.SUPER_MAKER
    ]
  },
  {
    path: '/customers/hub-account/phone/:phone/preview',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Customers/pages/EditHubAccount')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.HUB_PREVIEW_EXIST_REQUEST_EDIT_HUB,
    accessWhiteList: [
      USER_ROLES.CHECKER,
      USER_ROLES.MAKER,
      USER_ROLES.SUPER_CHECKER,
      USER_ROLES.SUPER_MAKER
    ]
  },
  {
    path: '/customers/hub-detail/:id',
    exact: true,
    component: lazy((): Promise<any> => import('pages/EmployeeDetail')),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.EMPLOYEE_DETAIL
  },
  {
    path: '/customers/hub-account/:id',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Customers/pages/HubAccountFrontPage')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.HUB_DETAIL_FRONT_PAGE
  },
  {
    path: '/customers/hub-account/phone/:phone',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Customers/pages/HubAccountFrontPage')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.HUB_DETAIL_FRONT_PAGE_PHONE
  },
  {
    path: '/customers/hub-account/:id/detail',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Customers/pages/HubAccountBackPage')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.HUB_DETAIL_BACK_PAGE,
    accessWhiteList: [
      USER_ROLES.CHECKER,
      USER_ROLES.MAKER,
      USER_ROLES.SUPER_CHECKER,
      USER_ROLES.SUPER_MAKER
    ]
  },
  {
    path: '/customers/hub-account/phone/:phone/detail',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Customers/pages/HubAccountBackPage')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.HUB_DETAIL_BACK_PAGE_PHONE,
    accessWhiteList: [
      USER_ROLES.CHECKER,
      USER_ROLES.MAKER,
      USER_ROLES.SUPER_CHECKER,
      USER_ROLES.SUPER_MAKER
    ]
  },
  {
    path: '/customers/hub-management',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Customers/pages/HubManagement')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.HUB_REQUEST_EDIT_MANAGEMENT,
    accessWhiteList: [
      USER_ROLES.CHECKER,
      USER_ROLES.MAKER,
      USER_ROLES.SUPER_CHECKER,
      USER_ROLES.SUPER_MAKER
    ]
  },
  {
    path: '/customers/hub-management/edit/:id',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Customers/pages/EditHubAccount')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.HUB_EDIT_EXIST_REQUEST_EDIT_HUB,
    accessWhiteList: [USER_ROLES.MAKER]
  },
  {
    path: '/customers/hub-management/edit/phone/:phone',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Customers/pages/EditHubAccount')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.HUB_EDIT_EXIST_REQUEST_EDIT_HUB,
    accessWhiteList: [USER_ROLES.MAKER]
  },
  {
    path: '/customers/corporate-detail/:id',
    exact: true,
    component: lazy(
      (): Promise<any> =>
        import('pages/Customers/pages/CorporateDetailFrontPage')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.CORPORATE_DETAIL_FRONT_PAGE
  },
  {
    path: '/customers/corporate-detail/:id/back-page',
    exact: true,
    component: lazy(
      (): Promise<any> =>
        import('pages/Customers/pages/CorporateDetailBackPage')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.CORPORATE_DETAIL_BACK_PAGE,
    accessWhiteList: [
      USER_ROLES.CHECKER,
      USER_ROLES.MAKER,
      USER_ROLES.SUPER_CHECKER,
      USER_ROLES.SUPER_MAKER
    ]
  },
  {
    path: '/customers/corporate-detail/:id/files-management',
    exact: true,
    component: lazy(
      (): Promise<any> =>
        import('pages/Customers/pages/CorporateFileManagement')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.CORPORATE_DETAIL_BACK_PAGE__FILE_MANAGEMENT,
    accessWhiteList: [
      USER_ROLES.CHECKER,
      USER_ROLES.MAKER,
      USER_ROLES.SUPER_CHECKER,
      USER_ROLES.SUPER_MAKER
    ]
  },
  {
    path: '/customers/corporate-detail/:corporateId/salary-disbursal-detail/:salaryPayrollId',
    exact: true,
    component: lazy(
      (): Promise<any> =>
        import(
          'pages/Customers/pages/CorporateDetailFrontPage/tabs/SalaryDisbursal/pages/Detail'
        )
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.SALARY_DISBURSAL_DETAIL
  },
  {
    path: '/customers/add-new-corporate',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Customers/pages/AddOrEditNewCorporate')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.CORPORATE_ADD_NEW_CORPORATE,
    accessWhiteList: [USER_ROLES.MAKER]
  },
  {
    path: '/customers/preview-corporate/:id',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Customers/pages/AddOrEditNewCorporate')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.CORPORATE_ONLY_PREVIEW_CORPORATE_AFTER_SUBMIT,
    accessWhiteList: [
      USER_ROLES.MAKER,
      USER_ROLES.CHECKER,
      USER_ROLES.SUPER_CHECKER,
      USER_ROLES.SUPER_MAKER
    ]
  },
  {
    path: '/customers/edit-request-add-corporate/:id',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Customers/pages/AddOrEditNewCorporate')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.CORPORATE_EDIT_REQUEST_ADD_CORPORATE,
    accessWhiteList: [USER_ROLES.MAKER]
  },
  {
    path: '/customers/edit-corporate/:id',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Customers/pages/AddOrEditNewCorporate')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.CORPORATE_REQUEST_EDIT_CORPORATE,
    accessWhiteList: [USER_ROLES.MAKER]
  },
  {
    path: '/customers/corporate-management',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Customers/pages/CorporateManagement')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.CORPORATE_MANAGEMENT,
    accessWhiteList: [
      USER_ROLES.CHECKER,
      USER_ROLES.MAKER,
      USER_ROLES.SUPER_CHECKER,
      USER_ROLES.SUPER_MAKER
    ]
  },
  {
    path: '/relationship-management',
    exact: true,
    component: lazy((): Promise<any> => import('pages/RelationshipManagement')),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.ADMIN,
    name: ROUTER_NAME_LIST.CORPORATE_DETAIL__RELATIONSHIP_MANAGEMENT
  },
  {
    path: '/customers/rm-profile/:userName',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Customers/pages/RMProfilePage')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.RM_PROFILE
  },
  {
    path: '/support',
    exact: true,
    component: lazy((): Promise<any> => import('pages/Support')),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.SUPPORT,
    name: ROUTER_NAME_LIST.SUPPORTS
  },
  {
    path: '/support/view-unregistered-account-requests/:entityId',
    exact: true,
    component: lazy(
      (): Promise<any> =>
        import('pages/Support/pages/RequestsOfUnregisteredAccount')
    ),
    layout: LAYOUT_NAME.BLANK,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.SUPPORT,
    name: ROUTER_NAME_LIST.SUPPORTS__VIEW_REQUESTS_OF_UNREGISTERED_ACCOUNT
  },
  {
    path: '/wps',
    exact: true,
    component: lazy((): Promise<any> => import('pages/WPS')),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.WPS,
    name: ROUTER_NAME_LIST.WPS
  },
  {
    path: '/wps/:wpsFileId/payment-files',
    exact: true,
    component: lazy((): Promise<any> => import('pages/WPS/pages/PaymentFiles')),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.WPS,
    name: ROUTER_NAME_LIST.WPS_PAYMENT_FILES
  },
  {
    path: '/wps/:wpsFileId/refund-files',
    exact: true,
    component: lazy((): Promise<any> => import('pages/WPS/pages/RefundFiles')),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.WPS,
    name: ROUTER_NAME_LIST.WPS_REFUND_FILES
  },
  {
    path: '/wps/:wpsId/return-files',
    exact: true,
    component: lazy((): Promise<any> => import('pages/WPS/pages/ReturnFiles')),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.WPS,
    name: ROUTER_NAME_LIST.WPS_RETURN_FILES
  },
  {
    path: '/wps/archive',
    exact: true,
    component: lazy((): Promise<any> => import('pages/WPS/pages/Archive')),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.WPS,
    name: ROUTER_NAME_LIST.WPS_EXCEPTIONALQUEUE_ARCHIVE
  },
  {
    path: '/enquiry',
    exact: true,
    component: lazy((): Promise<any> => import('pages/Enquiry')),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.ENQUIRY,
    name: ROUTER_NAME_LIST.ENQUIRY
  },
  {
    path: '/enquiry/transaction-history',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Enquiry/pages/TransactionHistory')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.ENQUIRY,
    name: ROUTER_NAME_LIST.TRANSACTION_HISTORY
  },
  {
    path: '/enquiry/batch-transactions/:batchNo/:accountId',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Enquiry/pages/BatchTransactions')
    ),
    isPrivate: true,
    layout: LAYOUT_NAME.BLANK,
    name: ROUTER_NAME_LIST.TRANSACTION_HISTORY_BATCHES
  },
  {
    path: '/notifications',
    exact: true,
    component: lazy((): Promise<any> => import('pages/Notifications')),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.NOTIFICATIONS,
    name: ROUTER_NAME_LIST.NOTIFICATIONS
  },
  {
    path: '/notifications/view-details/:id',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Notifications/pages/Details')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.NOTIFICATIONS,
    name: ROUTER_NAME_LIST.NOTIFICATIONS_DETAILS
  },
  {
    path: '/notifications/add-new-campaign',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Notifications/pages/CreateNewCampaign')
    ),
    isPrivate: true,
    layout: LAYOUT_NAME.ADMIN,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.NOTIFICATIONS,
    name: ROUTER_NAME_LIST.NOTIFICATIONS_CREATE_CAMPAIGN,
    accessWhiteList: [USER_ROLES.MAKER]
  },
  {
    path: '/first-time-login',
    exact: true,
    component: lazy((): Promise<any> => import('pages/FirstTimeLogin')),
    layout: LAYOUT_NAME.AUTHEN,
    isPrivate: false, // to force use auth layout
    name: ROUTER_NAME_LIST.FIRST_TIME_LOGIN
  },
  {
    path: '/change-password',
    exact: true,
    component: lazy((): Promise<any> => import('pages/ChangePassword')),
    layout: LAYOUT_NAME.AUTHEN,
    isPrivate: false,
    name: ROUTER_NAME_LIST.CHANGE_PASSWORD
  },
  {
    path: '/settings',
    exact: true,
    component: lazy((): Promise<any> => import('pages/settings')),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.SETTINGS,
    name: ROUTER_NAME_LIST.SETTINGS
  },
  {
    path: '/settings/fxrates/archive',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/settings/tabs/FxRates/Archive')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.SETTINGS,
    name: ROUTER_NAME_LIST.FX_RATES
  },
  {
    path: '/settings/limitmanagement/archive',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/settings/tabs/LimitManagement/Archive')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.SETTINGS,
    name: ROUTER_NAME_LIST.LIMIT_MANAGEMENT
  },
  {
    path: '/settings/remittancefees/archive',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/settings/tabs/RemittanceFees/Archive')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.SETTINGS,
    name: ROUTER_NAME_LIST.REMITTANCE_FEES
  },
  {
    path: '/settings/usergroup/add-new-group',
    exact: true,
    component: lazy(
      (): Promise<any> =>
        import('pages/settings/tabs/GroupName/pages/CreateGroup')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.SETTINGS,
    name: ROUTER_NAME_LIST.CREATE_USER_GROUP,
    accessWhiteList: [USER_ROLES.SUPER_MAKER]
  },
  {
    path: '/settings/user/add-new-user',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/settings/tabs/User/pages/CreateNewUser')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.SETTINGS,
    name: ROUTER_NAME_LIST.CREATE_USER,
    accessWhiteList: [USER_ROLES.SUPER_MAKER]
  },
  {
    path: '/settings/user-management',
    exact: true,
    component: lazy(
      (): Promise<any> =>
        import('pages/settings/tabs/User/pages/UserManagement')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.SETTINGS,
    name: ROUTER_NAME_LIST.USER_MANAGEMENT,
    accessWhiteList: [USER_ROLES.SUPER_MAKER, USER_ROLES.SUPER_CHECKER]
  },
  {
    path: '/settings/corporate-pricing-management',
    exact: true,
    component: lazy(
      (): Promise<any> =>
        import(
          'pages/settings/tabs/CorporatePricing/CorporatePricingManagement'
        )
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.SETTINGS,
    name: ROUTER_NAME_LIST.CORPORATE_PRICING_MANAGEMENT,
    accessWhiteList: [
      USER_ROLES.SUPER_MAKER,
      USER_ROLES.SUPER_CHECKER,
      USER_ROLES.MAKER,
      USER_ROLES.CHECKER
    ]
  },
  {
    path: '/settings/user/:id',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/settings/tabs/User/pages/UserDetail')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.SETTINGS,
    name: ROUTER_NAME_LIST.VIEW_DETAIL_USER,
    accessWhiteList: [USER_ROLES.SUPER_MAKER, USER_ROLES.SUPER_CHECKER]
  },
  {
    path: '/settings/user/:id/preview',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/settings/tabs/User/pages/UserPreview')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.SETTINGS,
    name: ROUTER_NAME_LIST.PREVIEW_DETAIL_USER,
    accessWhiteList: [USER_ROLES.SUPER_MAKER, USER_ROLES.SUPER_CHECKER]
  },
  {
    path: '/settings/usergroup/:id/edit',
    exact: true,
    component: lazy(
      (): Promise<any> =>
        import('pages/settings/tabs/GroupName/pages/EditUserGroup')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.SETTINGS,
    name: ROUTER_NAME_LIST.EDIT_USER_GROUP,
    accessWhiteList: [USER_ROLES.SUPER_MAKER]
  },
  {
    path: '/settings/usergroup/:id/edit-request',
    exact: true,
    component: lazy(
      (): Promise<any> =>
        import('pages/settings/tabs/GroupName/pages/EditUserGroup')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.SETTINGS,
    name: ROUTER_NAME_LIST.EDIT_USER_GROUP_REQUEST,
    accessWhiteList: [USER_ROLES.SUPER_MAKER]
  },
  {
    path: '/settings/user/:id/edit',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/settings/tabs/User/pages/EditUser')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.SETTINGS,
    name: ROUTER_NAME_LIST.EDIT_USER,
    accessWhiteList: [USER_ROLES.SUPER_MAKER]
  },
  {
    path: '/settings/user/:id/edit-request',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/settings/tabs/User/pages/EditUser')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.SETTINGS,
    name: ROUTER_NAME_LIST.EDIT_USER_REQUEST,
    accessWhiteList: [USER_ROLES.SUPER_MAKER]
  },
  {
    path: '/settings/usergroup/:groupId',
    exact: true,
    component: lazy(
      (): Promise<any> =>
        import('pages/settings/tabs/GroupName/pages/GroupNameDetail')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.SETTINGS,
    name: ROUTER_NAME_LIST.VIEW_DETAIL_USER_GROUP,
    accessWhiteList: [USER_ROLES.SUPER_MAKER, USER_ROLES.SUPER_CHECKER]
  },
  {
    path: '/settings/usergroup/:groupId/preview',
    exact: true,
    component: lazy(
      (): Promise<any> =>
        import('pages/settings/tabs/GroupName/pages/GroupNamePreview')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.SETTINGS,
    name: ROUTER_NAME_LIST.PREVIEW_USER_GROUP,
    accessWhiteList: [USER_ROLES.SUPER_MAKER, USER_ROLES.SUPER_CHECKER]
  },
  {
    path: '/settings/user-group-management',
    exact: true,
    component: lazy(
      (): Promise<any> =>
        import('pages/settings/tabs/GroupName/pages/GroupUserManagement')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.SETTINGS,
    name: ROUTER_NAME_LIST.USER_GROUP_MANAGEMENT,
    accessWhiteList: [USER_ROLES.SUPER_MAKER, USER_ROLES.SUPER_CHECKER]
  },
  {
    path: '/reports',
    exact: true,
    component: lazy((): Promise<any> => import('pages/Reports')),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.REPORTS,
    name: ROUTER_NAME_LIST.REPORTS
  },
  {
    path: '/internal',
    exact: true,
    component: lazy((): Promise<any> => import('pages/Admin')),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.ADMIN,
    name: ROUTER_NAME_LIST.ADMIN_PAGE,
    accessWhiteList: [
      USER_ROLES.CHECKER,
      USER_ROLES.MAKER,
      USER_ROLES.SUPER_CHECKER,
      USER_ROLES.SUPER_MAKER
    ]
  },
  {
    path: '/internal/sanctioned-countries/archive',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Admin/tabs/SanctionedCountries/Archive')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.ADMIN,
    name: ROUTER_NAME_LIST.ADMIN_SANCTIONED_COUNTRIES
  },
  {
    path: '/internal/internal-account-management',
    exact: true,
    component: lazy(
      (): Promise<any> =>
        import('pages/Admin/tabs/InternalAccount/InternalAccountManagement')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.ADMIN,
    name: ROUTER_NAME_LIST.ADMIN_INTERNAL_ACCOUNT_MANAGEMENT,
    accessWhiteList: [
      USER_ROLES.CHECKER,
      USER_ROLES.MAKER,
      USER_ROLES.SUPER_CHECKER,
      USER_ROLES.SUPER_MAKER
    ]
  },
  {
    path: '/internal/internal-account/:id',
    exact: true,
    component: lazy(
      (): Promise<any> =>
        import('pages/Admin/tabs/InternalAccount/InternalAccountDetail')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.ADMIN,
    name: ROUTER_NAME_LIST.ADMIN_INTERNAL_ACCOUNT_DETAILS,
    accessWhiteList: [
      USER_ROLES.CHECKER,
      USER_ROLES.MAKER,
      USER_ROLES.SUPER_CHECKER,
      USER_ROLES.SUPER_MAKER
    ]
  },
  {
    path: '/internal/internal-account/:id/edit',
    exact: true,
    component: lazy(
      (): Promise<any> =>
        import('pages/Admin/tabs/InternalAccount/EditInternalAccount')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.ADMIN,
    name: ROUTER_NAME_LIST.ADMIN_INTERNAL_ACCOUNT_EDIT_DETAILS,
    accessWhiteList: [USER_ROLES.MAKER]
  },
  {
    path: '/internal/internal-account/:id/edit-request',
    exact: true,
    component: lazy(
      (): Promise<any> =>
        import('pages/Admin/tabs/InternalAccount/EditInternalAccount')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.ADMIN,
    name: ROUTER_NAME_LIST.ADMIN_INTERNAL_ACCOUNT_EDIT_DETAILS_REQUEST,
    accessWhiteList: [USER_ROLES.MAKER]
  },
  {
    path: '/internal/internal-account/internal-account-detail/:id',
    exact: true,
    component: lazy(
      (): Promise<any> =>
        import(
          'pages/Admin/tabs/InternalAccount/InternalAccountManagement/RequestManagementDetail'
        )
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.ADMIN,
    name: ROUTER_NAME_LIST.ADMIN_INTERNAL_ACCOUNT_MANAGEMENT_REQUEST_DETAILS,
    accessWhiteList: [
      USER_ROLES.CHECKER,
      USER_ROLES.MAKER,
      USER_ROLES.SUPER_CHECKER,
      USER_ROLES.SUPER_MAKER
    ]
  },
  {
    path: '/customers/corporate-detail/:id/onboard-list',
    exact: true,
    component: lazy(
      (): Promise<any> =>
        import(
          'pages/Customers/pages/CorporateDetailFrontPage/tabs/OnboardManagement/OnboardManagementList'
        )
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.CORPORATE_VIEW_AND_MAINTAIN_UPLOADED_EMPLOYEE_LIST,
    accessWhiteList: [
      USER_ROLES.CHECKER,
      USER_ROLES.MAKER,
      USER_ROLES.SUPER_CHECKER,
      USER_ROLES.SUPER_MAKER
    ]
  },
  {
    path: `/customers/corporate-detail/:corporateId/unregistered-account/:id/unregistered-account-edit`,
    exact: true,
    component: lazy(
      (): Promise<any> =>
        import(
          'pages/Customers/pages/CorporateDetailFrontPage/EditUnregisteredAccount'
        )
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.CORPORATE_EDIT_UNREGISTERED_ACCOUNT,
    accessWhiteList: [USER_ROLES.MAKER]
  },
  {
    path: `/customers/corporate-detail/:corporateId/pending-account/:accountId/:id/pending-account-edit`,
    exact: true,
    component: lazy(
      (): Promise<any> =>
        import(
          'pages/Customers/pages/CorporateDetailFrontPage/EditUnregisteredAccount'
        )
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.CORPORATE_EDIT_PENDING_ACCOUNT,
    accessWhiteList: [USER_ROLES.MAKER]
  },
  {
    path: '/customers/corporate-detail/:corporateId/onboard-list/request-detail/:id',
    exact: true,
    component: lazy(
      (): Promise<any> =>
        import(
          'pages/Customers/pages/CorporateDetailFrontPage/tabs/OnboardManagement/PreviewRequest'
        )
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.CORPORATE_EDIT_PENDING_AND_UNREGISTERED_ACCOUNT_PREVIEW,
    accessWhiteList: [
      USER_ROLES.CHECKER,
      USER_ROLES.MAKER,
      USER_ROLES.SUPER_CHECKER,
      USER_ROLES.SUPER_MAKER
    ]
  },
  {
    path: `/customers/corporate-detail/:corporateId/onboard-list/:id/edit-request`,
    exact: true,
    component: lazy(
      (): Promise<any> =>
        import(
          'pages/Customers/pages/CorporateDetailFrontPage/EditUnregisteredAccount'
        )
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.CORPORATE_EDIT_REQUEST_PENDING_AND_UNREGISTERED_ACCOUNT,
    accessWhiteList: [USER_ROLES.MAKER]
  },
  {
    path: '/customer-support/customers',
    exact: true,
    component: lazy((): Promise<any> => import('pages/Customers')),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.SUPPORT_CUSTOMERS
  },
  {
    path: '/customer-support/customers/spoke-account-detail/:id',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Customers/pages/SpokeDetailFrontPage')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.SUPPORT_SPOKE_DETAIL_FRONT_PAGE
  },
  {
    path: '/customer-support/customers/spoke-account-detail/phone/:phone',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Customers/pages/SpokeDetailFrontPage')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.SUPPORT_SPOKE_DETAIL_FRONT_PAGE_PHONE
  },
  {
    path: '/customer-support/customers/hub-account/:phone',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Customers/pages/HubAccountFrontPage')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.SUPPORT_HUB_DETAIL_FRONT_PAGE
  },
  {
    path: '/customer-support/customers/spoke-account-detail/:id/back-page',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Customers/pages/SpokeDetailBackPage')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.SUPPORT_SPOKE_DETAIL_BACK_PAGE,
    accessWhiteList: [
      USER_ROLES.CHECKER,
      USER_ROLES.MAKER,
      USER_ROLES.SUPER_CHECKER,
      USER_ROLES.SUPER_MAKER
    ]
  },
  {
    path: '/customer-support/customers/spoke-account-detail/phone/:phone/back-page',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Customers/pages/SpokeDetailBackPage')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.SUPPORT_SPOKE_DETAIL_BACK_PAGE_PHONE,
    accessWhiteList: [
      USER_ROLES.CHECKER,
      USER_ROLES.MAKER,
      USER_ROLES.SUPER_CHECKER,
      USER_ROLES.SUPER_MAKER
    ]
  },
  {
    path: '/customer-support/customers/hub-account/:phone/detail',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Customers/pages/HubAccountBackPage')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.SUPPORT_HUB_DETAIL_BACK_PAGE,
    accessWhiteList: [
      USER_ROLES.CHECKER,
      USER_ROLES.MAKER,
      USER_ROLES.SUPER_CHECKER,
      USER_ROLES.SUPER_MAKER
    ]
  },
  {
    path: '/customer-support/support',
    exact: true,
    component: lazy((): Promise<any> => import('pages/Support')),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.SUPPORT,
    name: ROUTER_NAME_LIST.CALL_CENTER_SUPPORTS
  },
  {
    path: '/customers/spoke-account-detail/phone/:phone',
    exact: true,
    component: lazy(
      (): Promise<any> => import('pages/Customers/pages/SpokeDetailFrontPage')
    ),
    layout: LAYOUT_NAME.ADMIN,
    isPrivate: true,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.CUSTOMERS,
    name: ROUTER_NAME_LIST.SPOKE_DETAIL_FRONT_PAGE_PHONE
  },
  {
    path: '/settings/add-new-pricing',
    exact: true,
    component: lazy(
      (): Promise<any> =>
        import('pages/settings/tabs/CorporatePricing/CreateNewPricing')
    ),
    isPrivate: true,
    layout: LAYOUT_NAME.ADMIN,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.SETTINGS,
    name: ROUTER_NAME_LIST.CREATE_CORPORATE_PRICING,
    accessWhiteList: [USER_ROLES.MAKER]
  },
  {
    path: '/settings/monthly-subscription/edit-pricing',
    exact: true,
    component: lazy(
      (): Promise<any> =>
        import('pages/settings/tabs/CorporatePricing/EditMonthlySubscription')
    ),
    isPrivate: true,
    layout: LAYOUT_NAME.ADMIN,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.SETTINGS,
    name: ROUTER_NAME_LIST.EDIT_MONTHLY_SUBSCRIPTION,
    accessWhiteList: [USER_ROLES.MAKER]
  },
  {
    path: '/settings/monthly-subscription/:id/edit-pricing-request',
    exact: true,
    component: lazy(
      (): Promise<any> =>
        import('pages/settings/tabs/CorporatePricing/EditMonthlySubscription')
    ),
    isPrivate: true,
    layout: LAYOUT_NAME.ADMIN,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.SETTINGS,
    name: ROUTER_NAME_LIST.EDIT_MONTHLY_SUBSCRIPTION_REQUEST,
    accessWhiteList: [USER_ROLES.MAKER]
  },
  {
    path: '/settings/monthly-subscription/detail-request/:id',
    exact: true,
    component: lazy(
      (): Promise<any> =>
        import(
          'pages/settings/tabs/CorporatePricing/DetailRequestMonthlySubscription'
        )
    ),
    isPrivate: true,
    layout: LAYOUT_NAME.ADMIN,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.SETTINGS,
    name: ROUTER_NAME_LIST.DETAIL_MONTHLY_SUBSCRIPTION_REQUEST
  },
  {
    path: '/settings/onboarding-and-implementation/:feePointValue/edit-pricing',
    exact: true,
    component: lazy(
      (): Promise<any> =>
        import(
          'pages/settings/tabs/CorporatePricing/EditOnboardingandImplementation'
        )
    ),
    isPrivate: true,
    layout: LAYOUT_NAME.ADMIN,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.SETTINGS,
    name: ROUTER_NAME_LIST.EDIT_CORPORATE_PRICING,
    accessWhiteList: [USER_ROLES.MAKER]
  },
  {
    path: '/settings/onboarding-and-implementation/:id/edit-pricing-request',
    exact: true,
    component: lazy(
      (): Promise<any> =>
        import(
          'pages/settings/tabs/CorporatePricing/EditOnboardingandImplementation'
        )
    ),
    isPrivate: true,
    layout: LAYOUT_NAME.ADMIN,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.SETTINGS,
    name: ROUTER_NAME_LIST.EDIT_CORPORATE_PRICING_REQUEST,
    accessWhiteList: [USER_ROLES.MAKER]
  },
  {
    path: '/settings/onboarding-and-implementation/detail-pricing-request/:id',
    exact: true,
    component: lazy(
      (): Promise<any> =>
        import(
          'pages/settings/tabs/CorporatePricing/DetailOnboardingandImplementation'
        )
    ),
    isPrivate: true,
    layout: LAYOUT_NAME.ADMIN,
    adminActiveMenuKey: ADMIN_ACTIVE_KEY_MENU.SETTINGS,
    name: ROUTER_NAME_LIST.DETAIL_ONBOARDING_REQUEST
  }
]
